import React, { FunctionComponent } from "react"

export const TOS: FunctionComponent = () => {
  return (
    <main>
      <h1>Terms of Service</h1>
      <p>
        This app searches for specific labels on emails in your inbox, and
        removes them from your inbox. For example, all emails labeled with
        "autoarchive-1" will be removed from your inbox (but not deleted) after
        1 day.
      </p>
      <p>
        This behavior is the only thing you need to consent to while using this
        app
      </p>
    </main>
  )
}

export default TOS
